var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-card',{staticClass:"ivy-question",staticStyle:{"margin":"5px 0 30px 0"},attrs:{"shadow":_vm.isEdit ? 'hover' : 'never'}},[_c('div',{staticClass:"question-content",staticStyle:{"margin-top":"10px"}},[_c('div',[(_vm.isEdit)?[_c('div',{staticStyle:{"float":"right","padding":"3px 0"}},[(_vm.order > -1)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Remove","placement":"top"}},[_c('el-button',{attrs:{"type":"danger","size":"mini","disabled":_vm.removeDisabled},on:{"click":function($event){return _vm.$emit('removeSection', _vm.order)}}},[_c('i',{staticClass:"fas fa-times-circle"})])],1):_vm._e()],1)]:_vm._e(),(_vm.composePracticeId && _vm.composePracticeId > 0)?_c('router-link',{staticStyle:{"float":"right","padding":"3px 0"},attrs:{"to":{
            name: 'SatPracticeFromFullDetail',
            params: {
              composePracticeId: _vm.composePracticeId
            },
            query: { id: _vm.practice.id }
          }}},[_c('el-button',{attrs:{"type":"success","size":"mini","plain":""}},[_c('i',{staticClass:"fas fa-eye"})])],1):_c('router-link',{staticStyle:{"float":"right","padding":"3px 0"},attrs:{"to":{
            name: 'SatPracticeDetail',
            query: { id: _vm.practice.id }
          }}},[_c('el-button',{attrs:{"type":"success","size":"mini","plain":""}},[_c('i',{staticClass:"fas fa-eye"})])],1)],2)]),_c('div',{staticClass:"question-content-title"},[(_vm.order)?_c('kbd',[_vm._v(" Module "+_vm._s(_vm.order)+_vm._s(_vm.practice.subject ? (": " + (_vm.titleCase(_vm.practice.subject.name))) : "")+" ")]):_vm._e(),_c('div',{staticClass:"mt-3"},[_c('h4',[_c('b',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getCompanyExamTitle(_vm.practice.exam.title))}})])])]),_c('p',{staticClass:"row mb-2",staticStyle:{"align-items":"center"}},[_c('b',[_vm._v(" Questions: "+_vm._s(_vm.practice.exam.exam_questions ? _vm.practice.exam.exam_questions.length : _vm.practice.exam.exam_questions_count)+" ")]),(_vm.isEdit)?_c('el-tooltip',{staticClass:"ml-1",attrs:{"effect":"dark","content":"Add Questions","placement":"top"}},[(_vm.composePracticeId && _vm.composePracticeId > 0)?_c('router-link',{staticStyle:{"float":"right","padding":"3px 0"},attrs:{"to":{
              name: 'SatPracticeFromFullDetail',
              params: {
                composePracticeId: _vm.composePracticeId
              },
              query: { id: _vm.practice.id, isEdit: 1 }
            }}},[_c('el-button',{attrs:{"type":"success","size":"mini"}},[_c('i',{staticClass:"fa fa-plus"})])],1):_c('router-link',{staticStyle:{"float":"right","padding":"3px 0"},attrs:{"to":{
              name: 'SatPracticeDetail',
              query: { id: _vm.practice.id, isEdit: 1 }
            }}},[_c('el-button',{attrs:{"type":"success","size":"mini"}},[_c('i',{staticClass:"fa fa-plus"})])],1)],1):_vm._e()],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }